<template>
	<section class="order-overview center">
		<template v-if="$root.rwd < 2">
			<lottie :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
		</template>
		<div style="width:100%; max-width:1200px; z-index:2;">
			<quote-title label="歷史訂單" />
			<br>
			<br>
			<el-card :body-style="{ padding:0, overflow:'auto' }">
				<table v-if="$root.rwd < 2">
					<tr>
						<th class="theme_text">訂單編號</th>
						<th class="theme_text">訂單項目</th>
						<th class="theme_text">訂單總價</th>
						<th class="theme_text">訂單狀態</th>
						<th class="theme_text">下訂時間</th>
						<th />
					</tr>
					<tr v-for="(item, i) in list" :key="i">
						<td>
							<clipboard>
								<div class="text-to-copy">{{item.id}}</div>
							</clipboard>
						</td>
						<td>
							<div class="center">
								<div class="col">
									<div class="row" gutter="10" align-x="between" v-for="(subItem, j) in item.list" :key="`${i}-${j}`">
										<div class="row" gutter="10">
											<el-link type="primary">
												<router-link :to="`/product/${subItem.productId}`">{{subItem.name}}</router-link>
											</el-link>
											<span v-if="subItem.color || subItem.size">
												{{subItem.color}}
												<template v-if="subItem.color && subItem.size">、</template>
												{{subItem.size}}
											</span>
										</div>
										<small>（&times;{{subItem.amount}}）</small>
									</div>
								</div>
							</div>
						</td>
						<td>{{item.totalPrice | currencyFilter}}</td>
						<td>
							<el-tag :type="['info','primary','info','danger','success'][item.status.code]" effect="dark">
								{{item.status.message}}
							</el-tag>
						</td>
						<td>{{item.create_time | dateFilter}}</td>
						<td>
							<template v-if="item.status.code <= 1">
								<popconfirm @confirm="handleCancel(item)" placement="left">
									<h3><i class="el-icon-info" style="color:red;" />{{"\u3000"}}確定要刪除？</h3>
									<el-button slot="reference" type="danger" size="mini">取消訂單</el-button>
								</popconfirm>
							</template>
						</td>
					</tr>
				</table>
				<table v-else style="text-align:left;">
					<tr v-for="(item, i) in list" :key="i">
						<td>
							<div class="col" gutter="10">
								<div class="row" align-y="baseline">
									<span>訂單編號：</span>
									<clipboard style="flex:1; width:0; text-align:left;">
										<div class="text-to-copy">{{item.id}}</div>
									</clipboard>
								</div>
								<span align-self="left">下單時間：{{item.create_time | dateFilter}}</span>
								<div class="col">
									<div class="row" gutter="10" align-x="between" v-for="(subItem, j) in item.list" :key="`${i}-${j}`">
										<div class="row" gutter="10">
											<el-link type="primary">
												<router-link :to="`/product/${subItem.productId}`">{{subItem.name}}</router-link>
											</el-link>
											<span v-if="subItem.color || subItem.size">
												{{subItem.color}}
												<template v-if="subItem.color && subItem.size">、</template>
												{{subItem.size}}
											</span>
										</div>
										<small>（&times;{{subItem.amount}}）</small>
									</div>
								</div>
								<div class="row" align-y="center" align-x="between">
									<el-tag :type="['info','primary','info','danger','success'][item.status.code]" effect="dark">
										{{item.status.message}}
									</el-tag>
									<h3 class="theme_text"><b>{{item.totalPrice | currencyFilter}}</b></h3>
								</div>
								<div v-if="item.status.code <= 1" align-self="right">
									<popconfirm @confirm="handleCancel(item)" placement="left">
										<h3><i class="el-icon-info" style="color:red;" />{{"\u3000"}}確定要刪除？</h3>
										<el-button slot="reference" type="danger" size="mini">取消訂單</el-button>
									</popconfirm>
								</div>
							</div>
						</td>
					</tr>
				</table>
			</el-card>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
	components: {
		Popconfirm: () => import("@/components/popconfirm"),
		clipboard: () => import("@/components/clipboard"),
	},
	data() {
		return {}
	},
	computed: {
		...mapState("order", ["_orderList"]),
		list() {
			return [
				{
					"orderer": {
						"name": "wang, wei-ru",
						"cellphone": "0920123456",
						"email": "weiru.gbt@gmail.com"
					},
					"recipient": {
						"name": "wang, wei-ru",
						"cellphone": "0920123456",
						"email": "weiru.gbt@gmail.com",
						"zipcode": "24945",
						"city": "新北市",
						"district": "八里區",
						"address": "龍米路二段117巷39-1號"
					},
					"payment": "郵政劃撥",
					"title": "金枝演社喔",
					"uniform_numbers": "92001174",
					"list": [
						{
							"productId": "2b0e77cb-00d4-4631-80b0-b4573e3c6cdd",
							"optionId": "e159b4c3-158c-4a6d-b47d-6e50d9611925",
							"amount": 1,
							"color": "",
							"size": "",
							"name": "大國民進行曲DVD",
							"thumb": "https://static-goldenbough-test.s3.amazonaws.com/golden-bough-theatre/product-2b0e77cb-00d4-4631-80b0-b4573e3c6cdd/be2d5d71f0d709840258d852ae2a61e8.jpeg",
							"price": 499
						}
					],
					"id": "6cd76c12-622f-4955-95bc-de0c16450f46",
					"create_time": 1589944827506,
					"update_time": 1589944842479,
					"status": {
						"code": 2,
						"message": "已取消"
					},
					"accountId": "ab8d46cd-d4a8-418e-8711-d1e7bf67ee3c",
					"totalPrice": 599
				},
				{
					"orderer": {
						"name": "游蕙芬",
						"cellphone": "0958127503",
						"email": "huifen0987@gmail.com"
					},
					"recipient": {
						"name": "游蕙芬",
						"cellphone": "0958127503",
						"email": "huifen0987@gmail.com",
						"zipcode": "249",
						"city": "新北市",
						"district": "八里區",
						"address": "龍米路二段117巷39-1號"
					},
					"payment": "銀行匯款/ATM轉帳",
					"list": [
						{
							"productId": "2b0e77cb-00d4-4631-80b0-b4573e3c6cdd",
							"optionId": "e159b4c3-158c-4a6d-b47d-6e50d9611925",
							"amount": 1,
							"color": "",
							"size": "",
							"name": "大國民進行曲DVD",
							"thumb": "https://static-goldenbough-test.s3.amazonaws.com/golden-bough-theatre/product-2b0e77cb-00d4-4631-80b0-b4573e3c6cdd/be2d5d71f0d709840258d852ae2a61e8.jpeg",
							"price": 499
						},
						{
							"productId": "2b320d2b-98eb-44e0-86d7-4aa7e51376f3",
							"optionId": "b6832f05-85d4-4be6-8301-5ffd7c744f4e",
							"amount": 1,
							"color": "熱情紅",
							"size": "S",
							"name": "金枝花布T恤",
							"thumb": "https://static.goldenbough.com.tw/golden-bough-theatre/product-2b320d2b-98eb-44e0-86d7-4aa7e51376f3/bcc392e098ebd96bdf1880317c32eaf8.jpeg",
							"price": 450
						}
					],
					"id": "9128d898-350e-4b8e-99f8-6d75254745d7",
					"create_time": 1589252711062,
					"update_time": 1617005307850,
					"status": {
						"code": 2,
						"message": "已取消"
					},
					"accountId": "e5d8b89a-9512-4c48-968e-f72a7652f260",
					"totalPrice": 1049
				},
				{
					"orderer": {
						"name": "游蕙芬",
						"cellphone": "0958127503",
						"email": "huifen0987@gmail.com"
					},
					"recipient": {
						"name": "游蕙芬",
						"cellphone": "0958127503",
						"email": "huifen0987@gmail.com",
						"zipcode": "249",
						"city": "新北市",
						"district": "八里區",
						"address": "龍米路二段117巷39-1號"
					},
					"payment": "銀行匯款/ATM轉帳",
					"title": "金枝演社劇團",
					"uniform_numbers": "92001174",
					"list": [
						{
							"productId": "cb60dd78-6246-4488-a25d-2dca7fe5595e",
							"optionId": "a183d5f9-b547-46d6-90d8-cf8088fdefe8",
							"amount": 1,
							"color": "",
							"size": "",
							"name": "《祭特洛伊》劇本書（2005版）",
							"thumb": "https://static.goldenbough.com.tw/golden-bough-theatre/product-cb60dd78-6246-4488-a25d-2dca7fe5595e/5e2234c7f4178917ec70c21abfbe735b.jpeg",
							"price": 250
						},
						{
							"productId": "f15a24f9-7bdd-4ee3-b111-696a1099feb9",
							"optionId": "7344256e-005b-4eee-8cde-ed9db1b5d2ce",
							"amount": 1,
							"color": "雙色合購",
							"size": "",
							"name": "金枝炫彩毛巾",
							"thumb": "https://static.goldenbough.com.tw/golden-bough-theatre/product-f15a24f9-7bdd-4ee3-b111-696a1099feb9/4c8f25be3dd9ea08bba29fb5fa18b259.jpeg",
							"price": 230
						},
						{
							"productId": "b5d64656-0f9c-4d23-a734-fb3014edb35a",
							"optionId": "2cccdc19-36f8-46ce-92d1-b401dc5376d6",
							"amount": 1,
							"color": "",
							"size": "",
							"name": "黃金海賊王DVD",
							"thumb": "https://static-goldenbough-test.s3.amazonaws.com/golden-bough-theatre/product-b5d64656-0f9c-4d23-a734-fb3014edb35a/394a3edbdd78d0389431d204eabff3a4.jpeg",
							"price": 499
						}
					],
					"id": "164d4806-650e-484d-bb44-1a56d58e308c",
					"create_time": 1587972794931,
					"update_time": 1589252683087,
					"status": {
						"code": 2,
						"message": "已取消"
					},
					"accountId": "e5d8b89a-9512-4c48-968e-f72a7652f260",
					"totalPrice": 1079
				}
			]
		}
	},
	methods: {
		...mapActions("order", [
			"_getPersonalOrderList",
			"_cancelPersonalOrder"
		]),
		async handleCancel({ id }) {
			this.$message.success(await this._cancelPersonalOrder(id));
		}
	},
	created() {
		this._getPersonalOrderList()
	}
}
</script>

<style lang="scss" scoped>
.order-overview {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush2.png"),
		url("../../../assets/background/dot1.png"),
		url("../../../assets/background/dot2.png");
	background-position: center 70%, center 80%, center 80%;

	.lottie {
		position: absolute;
		width: 25%;
		max-width: 250px;
		bottom: 0;
		left: 5%;
		transform: translateY(20%);
	}
}
.el-card {
	border-radius: 20px;
}
table {
	width: 100%;
	white-space: nowrap;
	text-align: center;
}
tr:nth-child(even) {
	background: $theme-background;
}
th,
td {
	text-align: center;
	padding: 1em 2em;
}
td:first-child {
	max-width: 10em;

	.text-to-copy {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		color: #909399;
		cursor: pointer;
		text-decoration: underline;
	}
}
</style>